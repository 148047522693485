import aluAcc from '../../contents/images/windows/aluminium/accessories/ALU-ACCESSOIRES-POUR-FENETRES-ET-PORTES-FENETRES.svg';
import aluAccCoul from '../../contents/images/windows/aluminium/accessories/ALU-ACCESSOIRES-POUR-BAIES-ET-FENETRES-COULISSANTES.svg';
import aluP from '../../contents/images/windows/aluminium/accessories/ALU-POIGNEES-POUR-FENETRES-ET-PORTES-FENETRES.svg';
// import aluPfc from '../../contents/images/windows/aluminium/accessories/ALU-POIGNEES-POUR-BAIES-ET-FENETRES-COULISSANTES.svg';

import woodAcc from '../../contents/images/windows/bois/accessories/BOIS-ACCESSOIRES-POUR-FENETRES-ET-PORTES-FENETRES.svg';
import woodP from '../../contents/images/windows/bois/accessories/BOIS-POIGNEES-POUR-FENETRES.svg';
import woodPpf from '../../contents/images/windows/bois/accessories/BOIS-POIGNEES-POUR-PORTES-FENETRES.svg';

import pvcAcc from '../../contents/images/windows/pvc/accessories/PVC-ACCESSOIRES-POUR-FENETRES-EN-PCV.svg';
import pvcP from '../../contents/images/windows/pvc/accessories/PVC-POIGNEES-POUR-FENETRES-EN-PCV.svg';
import pvcPpf from '../../contents/images/windows/pvc/accessories/PVC-POIGNEES-POUR-PORTE-FENETRES-EN-PCV.svg';
import pvcPpfs from '../../contents/images/windows/pvc/accessories/PVC-POIGNEES-POUR-PORTE-FENETRES-VR-EN-PCV.svg';

const allAccessories = {
    aluminium: {
        bc: { acc: aluAccCoul, p: null },
        // bc: { acc: aluAccCoul, p: aluPfc },
        ca: { acc: aluAcc, p: aluP },
        fc: { acc: aluAccCoul, p: null },
        // fc: { acc: aluAccCoul, p: aluPfc },
        ob: { acc: aluAcc, p: aluP },
        obs: { acc: aluAcc, p: aluP },
        pfb: { acc: aluAcc, p: aluP },
        pfbs: { acc: aluAcc, p: aluP },
    },
    bois: {
        ca: { acc: woodAcc, p: woodP },
        fb: { acc: woodAcc, p: woodP },
        pfb: { acc: woodAcc, p: woodPpf },
    },
    pvc: {
        bc: { acc: null, p: null },
        // bc: { acc: pvcAcc, p: null },
        ca: { acc: pvcAcc, p: pvcP },
        ob: { acc: pvcAcc, p: pvcP },
        obs: { acc: pvcAcc, p: pvcP },
        pfb: { acc: pvcAcc, p: pvcPpf },
        pfbs: { acc: pvcAcc, p: pvcPpfs },
    },
};

export default allAccessories;
